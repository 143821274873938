<template>
  <div>
    <div id="menu">
        <span id="logo" class="left_logo active" > <a @click="goSection(1)" href="#section1"><img
            src="../../assets/img/etners-main/logo_efs.png"/></a> </span>
      <div class="right">
        <ul>
          <li v-if="!!loginUserData">
            <a v-if="$store.state.loginStore.saasMode" @click="$router.push({name:'PortalMypage'})">{{loginUserData.userNm}}님</a>
            <span v-else>{{loginUserData.userNm}}님</span>
          </li>
          <li v-if="!loginUserData"><a @click="fnGoLogin">LOGIN</a></li>
          <li v-else><a @click="fnLogout">LOGOUT</a></li>
          <li @click="goSection(9)" class="contactus pc"><a>CONTACT US</a></li>
          <li @click="isShow = !isShow" class="menu-button">
            <div>
              <svg data-v-125ef032="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                   role="img" aria-label="list" xmlns="http://www.w3.org/2000/svg"
                   fill="currentColor" class="bi-list b-icon bi" style="font-size: 200%;">
                <g data-v-125ef032="">
                  <path fill-rule="evenodd"
                        d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
                </g>
              </svg>
            </div>
          </li>
        </ul>
      </div>
      <!-- 모바일 사이드 메뉴-->
      <ul :class="isShow ? 'box_menu show' : 'box_menu hide'">
        <li data-menuanchor="Page1"><a @click="goSection(1)" >ABOUT EFS</a></li>
        <li data-menuanchor="Page2"><a @click="goSection(2)">통합 물류 운영 솔루션</a></li>
        <li data-menuanchor="Page3"><a @click="goSection(3)">Structure & Flow</a></li>
        <li data-menuanchor="Page4"><a @click="goSection(4)">PROCESS</a></li>
        <li data-menuanchor="Page5"><a @click="goSection(5)">BENEFIT</a></li>
        <li data-menuanchor="Page6"><a @click="goSection(6)">HISTORY</a></li>
        <li data-menuanchor="Page7"><a @click="goSection(7)">REFERENCE</a></li>
        <li data-menuanchor="Page8"><a @click="goSection(8)">CERTIFICATION</a></li>
        <!-- <li data-menuanchor="Page8"><a @click="goSection(8)">CONTACT US</a></li> -->
        <li data-menuanchor="Page10"><a @click="goSection(10)">소개서 다운받기</a></li>
        <li v-if="$store.state.loginStore.saasMode"><a @click="e =>{
          $router.push({name : 'Pricing'})
          isShow = false
        }">PRICING</a></li>
        <li v-if="$store.state.loginStore.saasMode && ( !loginUserData || loginUserData.authCd === Constant.efs.auth.TEMPORARY_USER)" class="contactus mobile">
          <a @click="e =>{
            if(!!loginUserData){
                $router.push({name : 'RequestService'})
                isShow = false
            }else {
                $store.dispatch('SET_IS_SHOW_LOGIN_POPUP', true)
            }
          }" class="btn_round"
          >서비스 신청</a>
        </li>
        <li v-if="!!loginUserData && loginUserData.authCd !== Constant.efs.auth.TEMPORARY_USER" class="contactus mobile">
          <a href="/enter" class="btn_round"
          >솔루션 바로가기</a>
        </li>
      </ul>
    </div>
    <div v-show="isShow" @click="isShow = false" class="dimmed" style="display:none"></div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import Constant from '@/common/constant'

export default {
  name: 'HeaderForLanding',
  computed : {
    ...mapGetters(['loginUserData'])
  },
  methods : {
    fnGoLogin : function (){
      window.location.href = `/login`
    },
    // 로그아웃 처리
    fnLogout: function () {
      const vm = this
      vm.$store.dispatch('LOGOUT').then(() => {
        window.location.href = `/landing`
      })
    },
    goSection : function (num){
      const vm = this
      vm.isShow = false
      vm.$store.dispatch('SET_IS_SHOW_LOGIN_POPUP', false)

      if(vm.$route.name==='Landing'){
        fullpage_api.moveTo(num)
      }else {

        let routeName = {
          name : 'Landing',
          params : {
            num : num
          }
        }
        this.$router.push(routeName)
      }
    }
  },
  data () {
    return {
      Constant,
      isShow : false,
    }
  },

}
</script>

<style scoped>

</style>
